<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';
import Spinner from "@/components/spinner";

export default {
    components: {
        Layout,
        PageHeader,
        Spinner
    },

    data() {
        return {
            title: "Import Sitemap",
            url: null,
            items: [],
            validationErroMsg: null,
            successMsg: null,
            errorMessages: [],
            urlData: [],
            page: 1,
            perPage: 30,
            pages: [],
            addUrlErrorMessage: "",
            addUrlSuccessMessage : "",
            isLoading : false,
            anyUrlsFound : false
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedWebsiteUrl'
        ]),
        displayedPosts() {
            return this.paginate(this.urlData);
        },
        resultQuery() {
            if (this.searchQuery) {
                const search = this.searchQuery.toLowerCase();
                return this.displayedPosts.filter((data) => {
                    return (
                        data.url.toLowerCase().includes(search)
                    );
                });
            } else {
                console.log("resultQuery: ", this.displayedPosts)
                return this.displayedPosts;
            }
        }

    },


    methods: {
        importSitemap() {
            this.isLoading = true;
            this.anyUrlsFound = false;

            api.getSitemapUrls(this.selectedWebisteId)
                .then(response => {
                    console.log(response);
                    this.errorMessages = [];
                    this.urlData = [];
                    this.pages = [];

                    for (let i = 0; i < response.data.urls.length; i++) {
                        this.urlData.push({
                            url: response.data.urls[i].url,
                            isSelected: false
                        });
                    }

                    if (this.urlData.length == 0) {
                        this.anyUrlsFound = true;
                    }

                    this.setPages();
                })
                .catch(error => {
                    console.log("error downloading sitemap", error);
                    let responseData = error.response.data;

                    if (typeof responseData.errors !== 'undefined') {
                        this.errorMessages = [];

                        for (let i = 0; i < responseData.errors.length; i++) {
                            var errorGroup = responseData.errors[i];

                            for (let j = 0; j < errorGroup.description.length; j++) {
                                this.errorMessages.push(errorGroup.description[j]);
                            }
                        }
                    }
                    else {
                        this.errorMessages = [`Unkown error. Sorry we could not download the sitemap for ${this.selectedWebsiteUrl}.`]
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        updateUrls() {
            var selectedUrls = this.urlData.filter(x => x.isSelected).map(x => x.url);
            var body = {
                urls: selectedUrls
            };

            this.isLoading = true;
            this.addUrlErrorMessage = "";
            this.addUrlSuccessMessage = "";

            api.upsertWebPageUrls(this.selectedWebisteId, body)
                .then(response => {
                    //todo: handle validation and success messages
                    console.log(response);

                    this.addUrlSuccessMessage = "Successfully added URL(s)";
                })
                .catch(() => {
                    this.addUrlErrorMessage = "Sorry, an unknown error occured. We could not add urls to the website, please try again later.";
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        paginate(data) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return data.slice(from, to);
        },

        setPages() {
            let numberOfPages = Math.ceil(this.urlData.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        }
    }
}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div>

                <!-- end col -->
                <div class="col">
                    <!-- card -->
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            <p class="
                            text-uppercase
                            fw-bold
                            text-truncate
                            mb-0
                            ">
                            Import Website Sitemap
                        </p>
                         
                        </div>
                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">
                            <div>
                                <p>Click import sitemap and we will download your website sitemap and you can select the pages you would like to manage</p>

                            </div>
                            <div class="text-center">
                            <button variant="primary" class="btn btn-primary btn-animation waves-effect waves-light mb-3 me-3"
                                data-text="Import Sitemap" @click="importSitemap()"><span>Import Sitemap</span></button>
                            </div>

                            <!-- start error block -->
                            <div id="errorMsg" v-show="errorMessages.length > 0">
                                <div class="card-body">
                                    <div class="card-title">Sitemap Import Errors</div>
                                    <ul class="list-group">
                                        <li v-for="error in errorMessages" :key="error.id"
                                            class="list-group-item list-group-item-danger">
                                            {{ error }}</li>
                                    </ul>
                                </div>
                            </div>
                            <!-- end error block -->

                            <!-- start table -->
                            <div v-if="errorMessages.length === 0" class="card-body">
                                <div class="table-responsive table-card">
                                    <table v-show="resultQuery.length > 1" class="table align-middle table-nowrap" id="customerTable">
                                        <thead class="table-light text-muted">
                                            <tr>
                                                <th class="sort" data-sort="currency_name" scope="col">Url</th>

                                                <th scope="col">Include</th>
                                            </tr>
                                        </thead>
                                        <!--end thead-->
                                        <tbody class="list form-check-all">
                                            <tr v-for="(data, index) of resultQuery" :key="index">
                                                <td class="id">
                                                    {{ data.url }}
                                                </td>

                                                <td>
                                                    <input v-model="data.isSelected" class="form-check-input mt-0"
                                                        type="checkbox" aria-label="Import url">
                                                </td>
                                            </tr>
                                        </tbody>
                                        <!--end tbody-->
                                    </table>
                                </div>
                                <div class="d-flex justify-content-end mt-3">
                                    <div class="pagination-wrap hstack gap-2">
                                        <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                            @click="page--">
                                            Previous
                                        </a>
                                        <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                            <li :class="{
                                              active: pageNumber == page,
                                              disabled: pageNumber == '...',
                                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                                :key="index" @click="page = pageNumber">
                                                <a class="page" href="#">{{ pageNumber }}</a>
                                            </li>
                                        </ul>
                                        <a class="page-item pagination-next" href="#" @click="page++"
                                            v-if="page < pages.length">
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- end table -->
                            <div v-if="anyUrlsFound === true" class="bg-info">
                                <p class="p-2">No URL's were found in sitemap</p>
                            </div>
                            <div v-if="addUrlErrorMessage !== ''" class="bg-danger">
                                <p class="p-2">{{ addUrlErrorMessage }}</p>
                            </div>
                            <div v-if="addUrlSuccessMessage !== ''" class="bg-success ">
                                <p class="p-2">{{ addUrlSuccessMessage }}</p>
                            </div>
                            <button :disabled="pages.length === 0" variant="primary" class="btn btn-primary btn-animation waves-effect waves-light mt-2"
                                data-text="Add Urls" @click="updateUrls()"><span>Add Urls</span></button>
                            <Spinner :isLoading="isLoading"></Spinner>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
        </div>
    </Layout>
</template>